
import { defineComponent, reactive } from "vue";

// Components
import Hero from "@/components/home/Hero.vue";
import Partners from "@/components/home/Partners.vue";
import AppLink from "@/components/global/AppLink.vue";

//
export default defineComponent({
  name: "Home",
  components: { Hero, Partners, AppLink },
  setup() {
    const infoUrls = reactive<{
      [key: string]: { title: string; url: string };
    }>({
      Token: {
        title: "/token/:tokenid",
        url: "/token/7a427a156fe70f83d3ccdd17e75804cc0df8c95c64ce04d256b3851385002a0b",
      },
      Transaction: {
        title: "/tx/:txid",
        url: "/tx/7a427a156fe70f83d3ccdd17e75804cc0df8c95c64ce04d256b3851385002a0b",
      },
      Address: {
        title: "/address/:address",
        url: "/address/simpleledger:qzmd5vxgh9m22m6fgvm57yd6kjnjl9qnwyztz2p80d",
      },
    });

    return {
      infoUrls,
    };
  },
});
