export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前正在维护的simpleledger.info，预计性能会下降。"])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在载入页面..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有代币"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["派息（空投）工具"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["源代码"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友情链接"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉，发生错误"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很抱歉给您带来不便，我们将尽快对此进行调查。 如果您愿意，<a href=\"https://github.com/blockparty-sh/slp-explorer\">您可以报告此错误</a>。<br>重新加载页面可能会有所帮助。"])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浏览器"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有代币"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简单分类帐协议代币浏览器"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查询基于比特币现金的代币"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本站基于SLPDB构建，SLPDB<a target=\"_blank\" href=\"https://github.com/simpleledger/SLPDB\">SLPDB</a>，是<a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\">BitDB FH 的分支</a>，<a target=\"_blank\" href=\"https://github.com/blockparty-sh/slp-explorer\">Github</a>。<br>在Github上查看代码并提交问题。<a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\">Electron Cash SLP Edition</a>."])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新交易"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易统计图表"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已毁灭代币"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易所:"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发送SLP交易"])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到SLP交易"])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币余额"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易统计图表"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误地址"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉，我们无法解码给定的地址。 仔细检查它是否拼写正确。"])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理SLP交易"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB当前正在处理此事务。 在高负载期间，处理交易可能需要更长的时间。 请稍后重试。"])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到交易"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在SLPDB或BitDB中找不到此事务。 它可能已经很旧或拼写错误。"])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404找不到:("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["抱歉，找不到您想要的页面。"])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请尝试其他搜索或返回浏览器，这可能是一个错误。 如果是这样，请<a href=\"https://github.com/blockparty-sh/slp-explorer\">报告</a>它！"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看已确认的所有交易"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有等待确认的交易"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["空投bch给哪些代币的持有者"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总共给代币持有者空投多少比特币现金"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不给这些地址空投，请每输入一个地址换一行"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用Electron cash钱包的“Pay to many”功能"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP资源管理器"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排除地址"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["派息（空投）工具"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比特币现金交易"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门代币"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有代币"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易次数"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易哈希"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易图表"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易编号"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币标识符"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币余额"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["符号"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版本"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区块高度"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["积木"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区块高度"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["锁定时间"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["块已创建"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["载入中..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在比特币现金浏览器上查看"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制到剪贴板"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一类"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可替代代币一族"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可替代代币一个孩子"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儿童不可替代代币"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["造币史"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毁灭历史"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待定"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[没有]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["丰富列表"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["统计"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币细节"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结余"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薄荷棒"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铸棒状态"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铸币指挥棒未使用的交易输出"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["薄荷"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建立"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币类型一生成交易没有任何SLP输入。<br>不可替代的代币子事务可以通过查看“代币详细信息”窗格中的“版本”行来看到输入。"])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自创世以来的有效交易"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效代币未花费的交易输出"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效代币地址"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聪锁定"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铸造币"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币烧毁"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["循环供应"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB地址错误"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有输入。"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内存池"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文献"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件网址"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件校验和"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创作者"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版本"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已验证"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入项"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产出"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输出向量"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间戳记"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小数点"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP地址"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款地址"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧版地址"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现金账户"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现金账户"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回后"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回首页"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新载入页面"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易哈希，地址或代币名..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非SLP交易"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP无效原因"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这不是SLP事务。"])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建代币"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发送"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已收到"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币标识符必填"])}
}