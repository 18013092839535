import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3cfc613e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_icon = _resolveComponent("info-icon")!
  const _component_app_link = _resolveComponent("app-link")!
  const _component_Copy = _resolveComponent("Copy")!

  return (_openBlock(), _createElementBlock("td", null, [
    (typeof _ctx.item == 'object')
      ? (_openBlock(), _createBlock(_component_Copy, {
          key: 0,
          class: _normalizeClass(["d-flex align-items-center", { 'word-brack-all': _ctx.item.warp }]),
          text: _ctx.item.copy
        }, {
          default: _withCtx(() => [
            (_ctx.item.tokenIcon)
              ? (_openBlock(), _createBlock(_component_info_icon, {
                  key: 0,
                  tokenId: _ctx.item.tokenIcon,
                  size: 32
                }, null, 8, ["tokenId"]))
              : _createCommentVNode("", true),
            (_ctx.item.url)
              ? (_openBlock(), _createBlock(_component_app_link, {
                  key: 1,
                  to: _ctx.item.url,
                  textContent: _toDisplayString(_ctx.item.text)
                }, null, 8, ["to", "textContent"]))
              : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.item.text), 1))
          ]),
          _: 1
        }, 8, ["class", "text"]))
      : (_ctx.item === '')
        ? (_openBlock(), _createElementBlock("pre", {
            key: 1,
            textContent: _toDisplayString(' ')
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.item), 1))
  ]))
}