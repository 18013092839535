
import { defineComponent, PropType } from "vue";
import { numberWithCommas } from "@/modules/utilities";

export default defineComponent({
  name: "AnalyticsCard",
  props: {
    title: String,
    value: Number as PropType<number | null>,
  },
  setup() {
    return {
      numberWithCommas,
    };
  },
});
