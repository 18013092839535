export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ang simpleledger.info ay karaniwang sumasailalim sa pagmamantini, asahan ang mahinang pagganap."])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nag-loloading ang view..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tignan ang lahat ng Tokens"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katulong sa Dibidendo"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinanggalingan ng Kodigo"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinagkukuhanan"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konek"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops… nagkaroon ng problema!"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patawad sa lahat ng abala, Ito ay titignan namin sa lalong madaling panahon. At kung nais mu, ikaw ay maaring magreport ng problemang ito. <a href=\"https://github.com/blockparty-sh/slp-explorer\">here</a>.<br>Ang pagreload ng pahinang ito ay maari ring makatulong."])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksplorer"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahat ng Tokens"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Tokens Eksplorer"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanapin ang mga tokens na ginawa sa Bitcoin Cash"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ang website naito ay ginawa sa <a target=\"_blank\" href=\"https://github.com/simpleledger/SLPDB\">SLPDB</a>, offshoot ng <a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\">BitDB FH</a>, tignan ang kodigo at i-submit ang lahat ng mga isyu sa <a target=\"_blank\" href=\"https://github.com/blockparty-sh/slp-explorer\">Github</a>.<br>Gumawa ng sarili mong tokens gamit ang <a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\">Electron Cash SLP Edition</a>."])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinakabago na mga Transaksyon"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksyon Tsarts"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinirang Tokens"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palitan:"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mga Ipinadalang SLP Transaksyon "])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mga Natanggap na SLP Transaksyon "])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mga Balance ng Token"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksyon Tsart"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maling Address"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasensya na, hindi namin mabasa ang address na ibinigay. Tignan muli kung wasto ang pababaybay."])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinoproseso na ang SLP Transaksyon"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ang SLPDB ay kasalukuyang pinoproseso ang transaksyong ito. Tuwing mataas ang  pagload ay maaaring matagalan ang pagproseso ng transaksyon.. Pakiulit na lang mamaya."])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ang Transaksyon ay Hindi makita"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ang transaksyong ito ay hindi makita sa SLPDB o BitDB. Ito ay maaring napakaluma na o mali ang pagbabaybay."])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 Hindi Makita :("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasensya, hindi makita ang pahinang iyong hinahanap ."])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulitin muli ang paghahanap o bumalik sa iyong browser, ito ay maaring isang bug lamang. Kapag ito nga ay bug, Ito ay <a href=\"https://github.com/blockparty-sh/slp-explorer\">i-report</a> na!"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tignan ang lahat ng transaksyong kumpimardo na sa isang bloke"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tignan ang lahat ng mga transaksyong hinihintay na makumpirma sa isang bloke"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alin sa mga nagmamay-ari ng tokens ang makakatanggap ng BCH"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["magkanong bch ang ipapadala sa mga nagmamay-ari ng tokens"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ilagay ang isang simpleledger: address bawat linya"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamitin \"Bumayad sa Lahat\" ang Electron Cash"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Eksplorer"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huwag pansinin ang mga Addresses"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katulong sa Dibidendo"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksyon sa Bitcoin Cash"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalkulahin"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mga Popular na Tokens"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lahat ng Tokens"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksyon"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mga Transaksyon"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Hash"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksyon Tsart"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Txid"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tx"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Id"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balanse ng Token"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pangalan"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symboliko"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilang"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bersyon"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mga Address"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloke"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mga Bloke"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taas ng Bloke"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oras ng Bloke"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ginawang Bloke"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nagloloading..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taon"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buwan"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linggo"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Araw"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tignan sa BCH Eksplorer"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopyahin sa clipboard"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type1"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-Group"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-Child [view parent]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child NFTs"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasaysayan ng Paggawa"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasaysayan ng Pagsira"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakabinbin"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[wala]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rich List"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalye"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balanse"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baton ng Paggawa"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status ng Minting Baton"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UTXO ng Minting Baton"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ipadala"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paggawa"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagsimula"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TokenType1 ang mga genesis transaksyon ay walang kahit anong SLP inputs.<br>NFT1 Child transactions can see the input from looking at the Version row in the Token Details pane."])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wastong Txns mula sa Pagsimula"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wastong UTXOs ng Token"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wastong  Addresses ng Token"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naipon na Satoshi"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ginawang Tokens"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinirang Tokens"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiikot na Supply"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB Address Error"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["walang inputs."])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uri"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mempool"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumento"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumento ng URI"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tsekeng Dokumento"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gumawa"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bersyon"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napatunayan"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inputs"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outputs"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vout"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mga decimals"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Address"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Address"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legacy Address"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Account"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Accounts"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bumalik"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pumunta sa Pinakapahina"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I-reload ang Pahina"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hanapin gamit ang txid, address o pangalan ng token..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HINDI-SLP Transaksyon"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Hindi Balidong Rason"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ito ay hindi isang  SLP transaksyon."])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mga Token na Ginawa"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["naipadala na"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["natanggap na"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kailangan ng Token Id"])}
}