import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    placeholder: _ctx.$t('search_placeholder'),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchWord) = $event)),
    onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.searchWord))),
    onKeypress: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.inputEvent && _ctx.inputEvent(...args)))
  }, null, 40, _hoisted_1)), [
    [_vModelText, _ctx.searchWord]
  ])
}