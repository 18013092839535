
// Modules
import { defineComponent, reactive } from "vue";

// Types
import { PropType } from "vue";
import { table_row } from "@/types/table.type";

// Components
import PaginationNav from "@/components/global/PaginationNav.vue";
import TableRow from "@/components/global/table/TableRow.vue";

//
export default defineComponent({
  components: { PaginationNav, TableRow },
  name: "PaginationTable",
  props: {
    headers: {
      type: Object as PropType<string[]>,
    },
    items: {
      type: Object as PropType<table_row[]>,
      required: true,
    },
    pages: {
      type: Number,
      required: true,
    },
    change: {
      type: Function as PropType<(index: number) => Promise<table_row[]>>,
      required: true,
    },
  },
  setup(props) {
    //
    let status = reactive({
      loading: false,
      items: props.items,
    });

    //
    async function onPaginationChange(index: number) {
      // Start loading
      status.loading = true;

      // Run change event
      status.items = await props.change(index);

      // Stop loading
      status.loading = false;
    }

    //
    return {
      status,
      onPaginationChange,
    };
  },
});
