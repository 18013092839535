
import { defineComponent, PropType } from "vue";
import AnalyticsCard from "@/components/global/analytics/AnalyticsCard.vue";

export default defineComponent({
  name: "AnalyticsContainer",
  components: { AnalyticsCard },
  props: {
    items: {
      type: Object as PropType<
        Array<{
          title: string;
          value: number | null;
        }>
      >,
    },
  },
});
