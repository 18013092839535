export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger.info는 현재 유지 보수 중이며 성능이 저하 될 것으로 예상합니다."])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지로드 중 ..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 토큰보기"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배당 도우미"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소스 코드"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자원"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잇다"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops sorry, an error occured"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["불편을 끼쳐 드려 죄송합니다. 최대한 빨리 조사하겠습니다. 원하는 경우이 오류를보고 할 수 있습니다<a href=\"https://github.com/blockparty-sh/slp-explorer\"> 여기 </a>.<br>페이지를 새로 고침하면 도움이 될 수 있습니다."])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탐침"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 토큰"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단순 원장 프로토콜 토큰 탐색기"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비트 코인 캐시에 구축 된 토큰 탐색"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 웹 사이트는<a target=\"_blank\" href=\"https://github.com/simpleledger/SLPDB\"> SLPDB </a>의 파생물<a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\"> BitDB FH </a>, 코드보기 및 문제 제출<a target=\"_blank\" href=\"https://github.com/blockparty-sh/slp-explorer\"> Github </a>.<br>를 사용하여 자신의 토큰을 만드십시오<a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\"> Electron Cash SLP 에디션 </a>."])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최신 거래"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 차트"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토큰 화상"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP 거래 보내기"])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받은 SLP 거래"])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토큰 잔액"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 차트"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잘못된 주소"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주어진 주소를 해독 할 수 없습니다. 철자가 틀린 것이 아닌지 다시 확인하십시오."])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP 거래 처리"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB가 현재이 트랜잭션을 처리 중입니다. 로드가 높은 동안 트랜잭션을 처리하는 데 시간이 더 걸릴 수 있습니다. 잠시 후 다시 시도하십시오."])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래를 찾을 수 없습니다"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이 트랜잭션은 SLPDB 또는 BitDB에서 찾을 수 없습니다. 매우 오래되었거나 철자가 잘못되었을 수 있습니다."])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 찾을 수 없음 :("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["찾고있는 페이지를 찾을 수 없습니다."])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다른 검색을 시도하거나 브라우저로 돌아가십시오. 버그 일 수 있습니다. 그렇다면 <a href=\"https://github.com/blockparty-sh/slp-explorer\"> 신고 </a>하십시오!"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록에서 확인 된 모든 거래보기"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록에서 확인 대기중인 모든 거래보기"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["어떤 토큰 소지자가 BCH를 받아야합니까"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토큰 보유자 사이에 얼마나 많은 bch를 보낼 것인가"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나의 simpleledger를 입력하십시오 : 한 줄에 주소"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전자 현금으로 \"많은 사람에게 지불\"사용"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP 탐색기"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소 무시"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배당 도우미"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비트 코인 현금 거래"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계산하다"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기있는 토큰"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 토큰"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["트랜잭션"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["업무"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 해시"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 차트"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 식별자"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["트랜잭션"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토큰 식별자"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토큰 잔액"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토큰"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토큰"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상징"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["양"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버전"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구애"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록 높이"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차단 시간"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["블록 생성"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로드 중 ..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["년"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["달"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비트 코인 현금 익스플로러에서보기"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["클립 보드에 복사"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["하나를 입력"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대체 불가능한 토큰 하나의 그룹"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대체 불가능 토큰 1 명의 자녀 [부모보기]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자녀가 교환 할 수없는 토큰"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["민트 역사"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["화상 기록"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보류 중"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[없음]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["풍부한 목록"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["통계"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세부"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["균형"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["민트 배턴"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배턴 상태"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["배턴 미사용 거래 출력"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보내다"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["민트"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창세기"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["토큰 유형 1 생성 트랜잭션에는 SLP 입력이 없습니다. <br> 대체 불가능 토큰 하나의 하위 트랜잭션은 토큰 세부 사항 분할 창의 버전 행에서 입력을 볼 수 있습니다."])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창세기 이래 유효한 거래"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효한 토큰 미사용 거래 출력"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유효한 토큰 주소"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사토시 갇혀"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발행 된 토큰"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구운 토큰"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순환 공급"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB 주소 오류"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력이 없습니다"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메모리 풀"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문서"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문서 위치"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문서 체크섬"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["창조자"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["버전"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검증"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출력"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출력 벡터"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["타임 스탬프"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소수"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP 주소"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현금 주소"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레거시 주소"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현금 계정"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현금 계정"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["돌아가다"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["집에가"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["페이지 새로 고침"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 식별자, 주소 또는 토큰 이름으로 검색 ..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비 SLP 거래"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP 잘못된 이유"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이것은 SLP 거래가 아닙니다"])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["생성 된 토큰"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보냄"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받았습니다"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["필요한 토큰 식별자"])}
}