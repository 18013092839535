import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "address-token-balances"
}
const _hoisted_2 = { class: "table-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pagination_table = _resolveComponent("pagination-table")!

  return (_ctx.tokenList.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("address_page_token_balances")) + " (" + _toDisplayString(_ctx.numberWithCommas(_ctx.balance.length)) + ") ", 1),
        _createVNode(_component_pagination_table, {
          headers: _ctx.status.headers,
          items: _ctx.tokenList,
          pages: _ctx.balance.allPage,
          change: _ctx.changePage
        }, null, 8, ["headers", "items", "pages", "change"])
      ]))
    : _createCommentVNode("", true)
}