export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itoju to rorun simpleledger.info ekurere nipa irorun iwe eleyi to gba itoju lowolowo Sise idanwo"])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igbese aworan"])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ile owo idakomu"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ile ipin iranlowo"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ipinle nkan"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ipinle"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isopo"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Àsìse tó farahàn"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emábìínú fun ìjànbá nà"])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olùpìnlè"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gbogbo ìdákòomu"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orí idakomu"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owó tí ako sórí ayélujára"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itùn Oro Ipinle To Ero ayelujara yii ni ako sori ifoju sun wonyi ti ayewo ti a si fi sowo sori awon to tele wonni <a target=\"_blank\" href=\"https://github.com/simpleledger/SLPDB\">SLPDB</a>, <a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\">BitDB FH</a>, <a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\">Electron Cash SLP</a>"])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isowò tó gbèyìn"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ìtan Isowò"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ìdakomu Jóná"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ìyípadà"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fifi esi owo ranse "])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gbigba Esi Owo "])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esi owo Idakomu"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yiyi Oro pada"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apejuwe to baje"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emabinu akolee ri apejuwe naa"])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fifi owo idakomu ranse"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB Tin fi esi na ranse"])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akori ese oro naa"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esi oro na kosi ninu SLPDB tabi BitDB"])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akori nomba naa"])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ema binu akori ojuewe ti e nwa."])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E gbiyanju oju ewe Miran"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yiye gbpgbo esi wo"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duro de gbogbo esi naa"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inu apo owo WO ni ki a fi eleyi si"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iye owo wo ni ki a fi ranse laarin awon apo wonyi"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fi oro eyokan ranse"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo ero irin lati fi owo na ranse"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titunse"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["koosile"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pinpin iranlowo"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eesi owo naa"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isiro"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okiki"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gbogbo owo"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esi oro"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esi oro"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esi tole"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oro esi"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Txid"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tx"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esi"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esi owo"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owo idakomu"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owo idakomu"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oruko"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apeere"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iye"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iru"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adugbo"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awon adugbo"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ile"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awon ile"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giga ile"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akoko ile"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kiko ile"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ifiranse..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odun"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osu"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ose"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OJo"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeewo"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titun ko"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iru akoko"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egbe"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obi omo [egbe]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omo NFTs"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itan"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisun Itan"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireti"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Ofo]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iwe Oloro"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itan"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekunrere"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esi"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igi"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esi igi"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igi"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fi ranse"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mint"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibere"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apejuwe Ibere"])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibere daadaa"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owo to niyi"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apo owo toniyi"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titipa"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owo idako"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owo tojo"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To kariaye"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apo ti ko dara"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosi oro"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iru"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibi owo"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iru"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ibi Iru"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iye iru"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluda"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iru"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yiyewo"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esi"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ita"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ofo"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["akoko"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["awọn ipin mẹwa"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apo owo"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owo apo"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apo to dara"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apo owo"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apo owo"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pada seyin"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lole"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atunse"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fi oruko apo wa owo na.."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kosi esi"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["idi to baje"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosi esi"])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dida apo owo"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["firanse"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gbigba"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owo idanimo"])}
}