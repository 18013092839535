export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger.info şu anda bakımdadır, performansta düşüşler olabilir."])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Tokenleri Göster"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temettü Yardımcısı"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Code"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaynaklar"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlan"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haydaa.. Bir hata oluştu!"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahatsızlıktan dolayı üzgünüz, en yakın zamanda bunu çözeceğiz. Eğer isterseniz bunu raporlayabilirsiniz. <a href=\"https://github.com/blockparty-sh/slp-explorer\"></a>.<br>Sayfayı yenilemek çözüm sağlayabilir."])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Tokenler"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Tokens Explorer"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin Cash üzerinde oluşturulan Tokenlere göz atın"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu websitesi <a target=\"_blank\" href=\"https://github.com/simpleledger/SLPDB\">SLPDB</a>,  <a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\">'nin bir offshoot'udur. BitDB FH</a>, kodları ve sorunları buraya rapolayabilirsiniz. <a target=\"_blank\" href=\"https://github.com/blockparty-sh/slp-explorer\">Github</a>.<br>Kendi tokenlarını oluştumak için <a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\">Electron Cash SLP Edition</a>."])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son İşlemler"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Grafikleri"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton Yakmalar"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borsalar:"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderilen SLP İşlemleri "])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alınan SLP İşlemleri "])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Bakiyesi"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Grafikleri"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatalı Adres"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüz, verilen adresi decode edemiyoruz. Verdiğiniz adresi tekrar kontrol ediniz."])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP İşlemi İşleniyor"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB bu işlemi şu anda işliyor. Yoğunluk sırasında işlemler uzun sürebilir. Lütfen birkaç dakika sonra tekrar deneyin."])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem bulunamadı"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu işlem SLPDB veya BitDB'de bulunamadı. Bu işlem çok eski veya hatalı olabilir."])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 Not Found :("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüz, aradığınız sayfayı bulamadık."])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başka bir arama deneyin veya tarayıcınıza geri dönün, bu bir bug olabilir. Eğer bir bug ise lütfen buraya raporlayın. <a href=\"https://github.com/blockparty-sh/slp-explorer\"></a>!"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokta onaylanan tüm işlemleri görüntüleyin"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokta onaylanmayı bekleyen tüm işlemleri görüntüleyin"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangi tokenlerin sahipleri BCH alacak"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token sahipleri arasında ne kadar bch gönderilecek"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger giriniz: her satıra bir adres"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electron Cash üzerinden \"Pay to Many\" kullan"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Explorer"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoksayılan Adresler"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temettü Yardımcısı"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin Cash İşlemi"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesapla"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popüler Tokenler"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Tokenler"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemler"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Hash'i"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlem Grafikleri"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Txid"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tx"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Id"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Bakiyesi"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokenler"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İsim"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sembol"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versiyon"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresler"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloklar"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok Yüksekliği"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok Zamanı"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok Oluşturuldu"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yükleniyor..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yıl"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ay"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hafta"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gün"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH Explorer'da görüntüle"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoya kopyala"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type1"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-Grup"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-Çocuk [view parent]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çocuk NFT'ler"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint Geçmişi"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakma Geçmişi"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekliyor"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[hiç]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rich Liste"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstatistikler"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayrıntılar"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bakiye"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint Baton"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mintlenen Baton Durumu"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mintlenen Baton UTXO"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gönder"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mint"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TokenType1 genesis işlemleri SLP girişine sahip değil.<br>NFT1 çocuk işlemlerinin girdileri Token Detayları sekmesinde Versiyon satırında görülebilir."])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis'ten beri geçerli Txns"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UTXOs Geçerli Tokenleri"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçerli token adresleri"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilitli Satoshiler"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mintlenen Tokenler"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakılan Tokenler"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dolaşımdaki Arz"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB Adres Hatası"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdi Yok."])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mempool"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doküman"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doküman URI"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doküman Checksum"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaratıcı"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versiyon"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onaylı"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Girdiler"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıktılar"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vout"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondalık"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Adresleri"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Adresleri"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legacy Adresleri"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Hesabı"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Hesapları"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri Git"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anasayfaya git"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayfayı Yenile"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Txid, adres veya token ismine göre ara..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NON-SLP İşlemi"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Geçersiz Nedeni"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu bir SLP işlemi değil."])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yaratılan Tokenler"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönderildi"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alındı"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekli Token ID"])},
  "nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT"])},
  "tokenstats_nft_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahip"])},
  "tokenstats_nft_burnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakılmış Txid"])}
}