import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isExternalLink)
    ? (_openBlock(), _createElementBlock("a", _mergeProps({ key: 0 }, _ctx.$attrs, {
        href: _ctx.url,
        target: "_blank"
      }), [
        _renderSlot(_ctx.$slots, "default")
      ], 16, _hoisted_1))
    : (_openBlock(), _createBlock(_component_router_link, _mergeProps({
        key: 1,
        to: _ctx.url
      }, _ctx.$attrs), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 16, ["to"]))
}