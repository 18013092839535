export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger.info в настоящее время проходит техническое обслуживание, ожидайте снижения производительности."])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка страницы..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть все токены"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивиденд Помощник"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исходный код"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подключения"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упс ... произошла ошибка!"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приносим извинения за неудобства, мы рассмотрим это как можно скорее. Если вы хотите, вы можете сообщить об этой ошибке <a href=\"https://github.com/blockparty-sh/slp-explorer\">here</a>.<br>Перезагрузка страницы может помочь."])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["исследователь"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все токены"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Обозреватель токенов"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр токенов, построенных на Bitcoin Cash"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот сайт построен поверх<a target=\"_blank\" href=\"https://github.com/simpleledger/SLPDB\"> SLPDB </a>,ответвление<a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\"> BitDB FH </a>,просмотреть код и отправить вопросы на<a target=\"_blank\" href=\"https://github.com/blockparty-sh/slp-explorer\"> Github </a>.<br>Сделайте свои собственные токены, используя<a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\"> Электронное кассовое издание SLP </a>."])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние транзакции"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Графики транзакций"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обозначения маркеров"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмены:"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправленные транзакции SLP "])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученные SLP транзакции "])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен Балансы"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["График транзакций"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный адрес"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извините, мы не можем расшифровать указанный адрес. Дважды проверьте, что это не ошибка."])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка SLP-транзакции"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB в настоящее время обрабатывает эту транзакцию. Во время высокой нагрузки обработка транзакций может занять больше времени. Пожалуйста, попробуйте снова через пару минут."])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция не найдена"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта транзакция не была найдена ни в SLPDB, ни в BitDB. Возможно, он был очень стар или введен в заблуждение."])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 не обнаружена :("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извините, не могу найти страницу, которую вы искали."])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, попробуйте другой поиск или вернитесь в браузер, возможно, это ошибка. Если это было, пожалуйста <a href=\"https://github.com/blockparty-sh/slp-explorer\">доклад</a> Это!</p>"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр всех транзакций, подтвержденных в блоке"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр всех транзакций, ожидающих подтверждения в блоке"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обладатели токена должны получить BCH"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сколько BCH отправить между держателями токенов"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enter one simpleledger: address per line"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["использование \"Плати многим\" с участием Electron Cash"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP исследователь"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игнорировать адреса"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивиденд Помощник"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin Cash Сделка"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассчитать"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярные токены"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все токены"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделка"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["операции"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хэш транзакции"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["График транзакций"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер транзакции"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделка"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор токена"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токен Баланс"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["знак"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лексемы"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["название"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["количество"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версия"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["блок"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блоки"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высота блока"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок Время"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок создан"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["загрузка ..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделю"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Смотреть на BCH исследователь"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать в буфер обмена"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type1"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не заменимый токен 1 группа"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не заменимый токен 1 ребенок [просмотреть родителя]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детские не заменимые токены"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мятная история"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История ожогов"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ожидании"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[никто]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Богатый список"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подробности"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мятный жезл"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус чеканки батона"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чеканка жезла неизрасходованных транзакций"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мята"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["генезис"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции генезиса типа 1 не имеют входов SLP. Не заменимые дочерние транзакции токена могут видеть входные данные, просматривая строку «Версия» на панели «Сведения о токене»."])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимые транзакции с токенами"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительный токен, неизрасходованные выходные данные транзакции"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительные адреса токенов"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сатоши заперт"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жетоны чеканились"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жетоны сожжены"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Циркуляционная поставка"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка адреса SLPDB"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет входов."])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пул памяти"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документ"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расположение документа"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрольная сумма документа"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["творец"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Версия"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверенный"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["входные"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выходы"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вектор выхода"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отметка"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["десятичные"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес SLP"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес наличными"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наследственный адрес"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассовый счет"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Денежные счета"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возвращаться"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идти домой"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["перезагрузить страницу"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск по идентификатору транзакции, адресу или имени токена..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["транзакция без SLP"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP неверная причина"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это не транзакция SLP."])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жетоны созданы"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получено"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется идентификатор токена"])}
}