import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bec4b0cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading",
  role: "status"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "icon my-1",
    style: _normalizeStyle(`--icon-size: ${_ctx.iconSize}`)
  }, [
    (_ctx.status.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("img", {
      src: _ctx.status.imageUrl,
      key: _ctx.status.imageUrl,
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.imageLoadDone && _ctx.imageLoadDone(...args))),
      onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.imageLoadError && _ctx.imageLoadError(...args)))
    }, null, 40, _hoisted_2)), [
      [_vShow, !_ctx.status.loading]
    ])
  ], 4))
}