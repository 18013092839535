
//
import { computed, defineComponent, reactive } from "vue";

//
export default defineComponent({
  name: "PaginationNav",
  props: {
    itemsLength: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 7,
    },
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  setup(props, context) {
    //
    const status = reactive({
      selectPage: props.modelValue,
      itemsPerPage:
        props.itemsPerPage > props.itemsLength
          ? props.itemsLength // ItemLength small than itemsPerPage
          : props.itemsPerPage,
    });

    //
    const startPage = computed(() => {
      //
      if (props.itemsPerPage > props.itemsLength) {
        return 0;
      }

      //
      if (props.itemsLength - 2 <= status.selectPage) {
        return props.itemsLength - props.itemsPerPage;
      }

      //
      if (status.selectPage - 4 > 0) {
        return status.selectPage - 4;
      }

      //
      return 0;
    });

    //
    function changePage(number: number) {
      const index = number - 1;

      //
      status.selectPage = number;

      //
      context.emit("change", index);
    }

    return { status, startPage, changePage };
  },
});
