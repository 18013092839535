import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e4e5caa"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "search-bar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_search = _resolveComponent("input-search")!

  return (_ctx.openFromOtherHomePage)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "slider"
      }, {
        default: _withCtx(() => [
          (!_ctx.route.meta.removeNavBar)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("i", {
                  class: "fi fi-rr-search px-3",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.searchMethod && _ctx.searchMethod(...args)))
                }),
                _createVNode(_component_input_search, {
                  modelValue: _ctx.searchWord,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchWord) = $event))
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}