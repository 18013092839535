
import { defineComponent, ref } from "vue";
import useSearch from "@/use/useSearch";

// Components
import InputSearch from "@/components/global/search/InputSearch.vue";

//
export default defineComponent({
  name: "GroupSearch",
  components: { InputSearch },
  setup() {
    //
    let { search } = useSearch();

    //
    const searchWord = ref("");

    //
    function searchMethod() {
      search(searchWord.value);
    }

    //
    return { searchWord, searchMethod };
  },
});
