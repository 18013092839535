import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tables-container" }
const _hoisted_2 = { class: "tx-inputs" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "tx-outputs" }
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", {
        class: "table-title",
        textContent: _toDisplayString(_ctx.$t('inputs'))
      }, null, 8, _hoisted_3),
      _createVNode(_component_Table, {
        headers: _ctx.headers,
        items: _ctx.status.inputs,
        "empty-message": _ctx.$t('no_inputs')
      }, null, 8, ["headers", "items", "empty-message"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", {
        class: "table-title",
        textContent: _toDisplayString(_ctx.$t('outputs'))
      }, null, 8, _hoisted_5),
      _createVNode(_component_Table, {
        headers: _ctx.headers,
        items: _ctx.status.outputs
      }, null, 8, ["headers", "items"])
    ])
  ]))
}