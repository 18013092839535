
import { useClipboard } from "@/use/useClipboard";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Copy",
  props: {
    text: {
      type: String,
    },
  },
  setup(props) {
    //
    const { copy } = useClipboard();

    //
    const showPopup = ref(false);

    //
    function copyText() {
      if (props.text) {
        copy(props.text);

        //
        showPopup.value = true;

        //
        setTimeout(() => {
          showPopup.value = false;
        }, 2000);
      }
    }

    //
    return { copyText, showPopup };
  },
});
