
import { defineComponent, reactive, computed } from "vue";
import { toSvg } from "jdenticon";

// Types
import { PropType } from "vue";

//
export default defineComponent({
  name: "InfoIcon",
  props: {
    tokenId: {},
    url: {
      type: String,
      default: "",
    },
    size: {
      type: Number as PropType<128 | 64 | 32 | 0>,
      default: 128,
    },
  },
  setup(props, context) {
    let status = reactive({
      loading: true,
      error: false,
      iconSize: "",
      imageUrl: props.url,
    });

    //
    function imageLoadError() {
      // Check from handling status
      if (status.error) {
        // Stop for loop error
        return;
      }

      // Change error state
      status.error = true;

      // Create svg icon for token
      let svgString = toSvg(props.tokenId, props.size);

      // Convert svg to base64
      status.imageUrl = "data:image/svg+xml;base64," + btoa(svgString);
    }

    //
    function imageLoadDone() {
      status.loading = false;

      //
      context.emit("loading");
    }

    //
    const iconSize = computed(() =>
      props.size === 0 ? "80%" : `${props.size}px`
    );

    //
    return { imageLoadError, imageLoadDone, iconSize, status };
  },
});
