export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger.info actualmente en mantenimiento, espera un rendimiento degradado."])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando página..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los tokens"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividendo Ayudante"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código fuente"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Vaya ... se produjo un error!"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disculpe las molestias, lo analizaremos lo antes posible. Si lo desea, puede informar este error <a href=\"https://github.com/blockparty-sh/slp-explorer\">here</a>.<br>Recargar la página puede ayudar."])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorador"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las fichas"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorador de tokens SLP"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque tokens creados con Bitcoin Cash"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este sitio web está construido sobre<a target=\"_blank\" href=\"https://github.com/simpleledger/SLPDB \"> SLPDB </a>,una rama de<a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\"> BitDB FH </a>, ver código y enviar problemas en<a target=\"_blank\" href=\"https://github.com/blockparty-sh/slp-explorer\"> Github </a>.<br>Haz tus propios tokens usando<a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\"> Electron Cash SLP Edition </a>"])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas transacciones"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráficos de transacciones"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichas quemadas"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambios:"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones SLP enviadas "])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones SLP recibidas "])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance de fichas"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de transacciones"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección incorrecta"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, no podemos decodificar la dirección dada. Verifique que no esté mal escrito."])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento de transacciones SLP"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB está procesando actualmente esta transacción. Durante una sobrecarga, puede llevar más tiempo procesar las transacciones. Por favor intente nuevamente en unos momentos."])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción no encontrada"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta transacción no se encontró en SLPDB o BitDB. Puede haber sido muy viejo o mal escrito."])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 no encontrado :("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, no puedo encontrar la página que estabas buscando."])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intente otra búsqueda o regrese a su navegador, esto puede haber sido un error. Si fue así, por favor <a href=\"https://github.com/blockparty-sh/slp-explorer\">report</a> it!</p>"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas las transacciones confirmadas en un bloque"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas las transacciones que esperan ser confirmadas en un bloque"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["que ficha\"s los titulares deberian recibir bch"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuánto bch enviar entre los titulares de tokens"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingrese un simpleledger: dirección por línea"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar \"Pagar a muchos\" con Electron Cash"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorador SLP"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar direcciones"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividendo Ayudante"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción en efectivo de Bitcoin Cash"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichas populares"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las fichas"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciónes"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash de transacción"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de transacciones"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Txid"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tx"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Id"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance de ficha"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ficha"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichas"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simbolo"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloque"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloques"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura del bloque"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo del bloque"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloque creado"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dia"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver en BCH Explorer"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar al portapapeles"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo 1"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo NFT1"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-Niña [ver madre]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT infantiles"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia de menta"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de quema"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[ninguna]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de ricos"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batuta de menta"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del bastón de menta"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batuta de menta UTXO"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enviar"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menta"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génesis"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las transacciones de génesis TokenType1 no tienen ninguna entrada SLP. <br> Las transacciones secundarias NFT1 pueden ver la entrada mirando la fila Versión en el panel Detalles de la ficha."])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Txns válidas desde el Génesis"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token UTXO válidos"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones de token válidas"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satoshis Bloqueados"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichas acuñadas"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichas quemadas"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suministro circulante"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de dirección SLPDB"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin entradas."])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mempool"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento URI"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma de comprobación de documentos"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creador"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versión"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificado"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entradas"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salidas"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vout"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca de tiempo"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimales"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección SLP"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de efectivo"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección heredada"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de efectivo"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas en efectivo"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresa"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ve al inicio"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargar página"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por txid, dirección o nombre de token..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción NO SLP"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo inválido de SLP"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta no es una transacción SLP."])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichas creadas"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación de token requerida"])}
}