export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger.info वर्तमान में रखरखाव के दौर से गुज़र रहा है । निचला कार्यप्रदर्शन की उम्मीद रखें ।"])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पृष्ठ आरोपण हो रहा है..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी मुद्राएँ देखें"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाभांश सहायक"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्रोत"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साधन"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जुड़िये"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ओह ! एक त्रुटि हुई !"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["असुविधा के लिए खेद है । हम जल्द से जल्द इसकी जाँच करेंगे । यदि आप चाहें, तो आप इस त्रुटि की <a href=\"https://github.com/blockparty-sh/slp-explorer\">सूचना</a> दे सकते हैं ।<br>पृष्ठ को पुनः आरोपण करने से शायद यह त्रुटि सुलझ सके ।"])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्वेषक"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी मुद्राएँ"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सरल खाता संविद (स॰खा॰सं॰) मुद्रा अन्वेषक"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिटकॉइन कॅश पर निर्मित मुद्राएँ देखें"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह जालस्थान <a href=\"https://github.com/simpleledger/SLPDB\">SLPDB</a> पर बनाई गई है, जो <a href=\"https://github.com/fountainhead-cash/bitd\">BitDB FH</a> का एक शाखा है । स्रोत की जाँच और मुद्दों को जमा <a href=\"https://github.com/blockparty-sh/slp-explorer\">गिटहब</a> पर करें ।<br>अपनी ख़ुद की मुद्रा बनाएँ <a href=\"https:https://github.com/simpleledger/Electron-Cash-SLP/releases/\">इलेक्ट्रॉन कॅश स॰खा॰सं॰ संस्करण</a> से ।"])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नवीनतम लेन-देन"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन लेखा-चित्र"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जलायी गई मुद्राएँ"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विनिमय केंद्र"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रेषित किये गये स॰खा॰सं॰ लेन-देन"])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त किये गये स॰खा॰सं॰ लेन-देन"])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्राओं का शेष"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन लेखा-चित्र"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग़लत संकेत"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्षमा करें, हम प्रदान किए गए संकेत को समझ नहीं पाए । सत्यापित करें कि वह ग़लत वर्तनी तो नहीं ।"])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स॰खा॰सं॰ लेन-देन को संसाधित कर रहे हैं"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB इस लेन-देन को इस समय संसाधित कर रहा है । परिश्रम के दौरान लेन-देन को संसाधित करने में थोड़ा अधिक समय लग सकता है । कृपया बाद में पुनः प्रयास करें ।"])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन नहीं मिला"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह लेन-देन SLPDB या BitDB में नहीं मिला था । यह बहुत पुराना हो सकता है या उसका ग़लत वर्तनी हो सकती है ।"])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 नहीं मिला :("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्षमा करें, आप जिस पृष्ठ की तलाश कर रहे थे वह नहीं मिला ।"])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया कोई दूसरा प्रयास करें या अपने संचारक पर पीछे जाएँ, यह एक त्रुटि हो सकती है । यदि कोई त्रुटि है, तो कृपया उसकी <a href=\"https://github.com/blockparty-sh/slp-explorer\">सूचना दें</a>!"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक खंड में सभी पुष्टि किए गए लेन-देन देखें"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एक खंड में पुष्टि होने की प्रतीक्षा कर रहे सभी लेन-देन देखें"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["किस मुद्रा धारक को BCH प्राप्त होना चाहिए"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा धारकों के बीच कितना BCH भेजना है"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हर एक पंक्ति में एक ही स॰खा॰सं॰ संकेत लिखिए"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इलेक्ट्रॉन कॅश के \"Pay to Many\" का उपयोग करें"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स॰खा॰सं॰ अन्वेषक"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इन संकेतों की उपेक्षा करें"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाभांश सहायक"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिटकॉइन कॅश लेन-देन"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गणें"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रचलित मुद्राएँ"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी मुद्राएँ"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन पहचानकर्ता"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन लेखा-चित्र"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन पहचानकर्ता"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा पहचानकर्ता"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा शेष"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्राएँ"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतीक"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रक़म"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संस्करण"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संकेत"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संकेत"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खंड"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खंड"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खंड ऊंचाई"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खंड समय"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाया गया खंड"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आरोपण हो रहा है..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साल"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महीना"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सप्ताह"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिन"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बिटकॉइन कॅश अन्वेषक पर देखें"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कर्तन पट्ट पर प्रतिलिपि करें"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार 1"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-समूह"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-बाल [जनक देखें]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT बाल"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ढालने का इतिहास"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जलाने का इतिहास"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आसन्न"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[कोई नहीं]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समृद्ध सूची"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आँकड़े"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शेष"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ढालने का डंडा"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ढालने का डंडा का परिस्थिति"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ढालने का डंडा का अव्ययित लेन-देन निर्गम"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजने"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ढालने"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पत्ति"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार 1 के मुद्रा उत्पत्ति लेन-देन में कोई स॰खा॰सं॰ आगम नहीं हते हैं ।<br>NFT1 बाल लेन-देन \"मुद्रा विवरण\" फलक में \"संस्करण\" पंक्ति को देखने से आगम देख सकते हैं ।"])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उत्पत्ति के बाद से वैध लेन-देन"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैध अव्ययित लेन-देन निर्गम"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वैध संकेत"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ताला लगाए हूए सतोशि"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ढाले गए मुद्राएँ"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जलाए गए मुद्राएँ"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिसंचारी आपूर्ति"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB संकेत त्रुटि"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई आगम नहीं ।"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकार"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्मरणकुंड"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दस्तावेज़"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दस्तावेज़ संकेत"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दस्तावेज़ सत्यापन मूल्य"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रचनाकार"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संस्करण"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सत्यापित"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आगम"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्गम"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["निर्गम वाहक"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टाइमस्टैम्प"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दशमलव संख्या"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स॰खा॰सं॰ संकेत"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॅश संकेत"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लिगेसी  संकेत"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रोकड़ खाता"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रोकड़ खाते"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापस जाएँ"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["घर जाएँ"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पृष्ठ पुनः आरोपण करें"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेन-देन पहचानकर्ता, संकेत या मुद्रा के नाम से खोजें ..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ग़ैर-स॰खा॰सं॰ लेन-देन"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स॰खा॰सं॰ अवैध कारण"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह एक स॰खा॰सं॰ लेन-देन नहीं है ।"])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाए गए मुद्राएँ"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भेजा गया"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्राप्त किया हुआ"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुद्रा पहचानकर्ता की आवश्यकता है"])}
}