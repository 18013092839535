import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tx-page container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_container = _resolveComponent("info-container")!
  const _component_Table = _resolveComponent("Table")!
  const _component_token_balances = _resolveComponent("token-balances")!
  const _component_address_tx = _resolveComponent("address-tx")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createBlock(_component_Loading, {
    loading: _ctx.loading,
    error: _ctx.error
  }, {
    default: _withCtx(() => [
      (_ctx.result)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_info_container, {
              address: _ctx.address,
              title: _ctx.$t('address')
            }, null, 8, ["address", "title"]),
            _createVNode(_component_Table, { items: _ctx.addresses }, null, 8, ["items"]),
            _createVNode(_component_token_balances, {
              balance: _ctx.result.balance,
              "get-balance": _ctx.getBalance
            }, null, 8, ["balance", "get-balance"]),
            _createVNode(_component_address_tx, {
              transaction: _ctx.result.transaction,
              "get-transaction": _ctx.getTransaction
            }, null, 8, ["transaction", "get-transaction"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading", "error"]))
}