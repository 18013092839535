export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger.infoは現在メンテナンス中です。パフォーマンスの低下が予想されます。"])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページを読み込んでいます..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのトークンを表示"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配当ヘルパーツール"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソースコード"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資源"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続する"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラーが発生しました！"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご不便をおかけして申し訳ありませんが、できるだけ早急に調査いたします。 必要に応じて、このエラーを<a href=\"https://github.com/blockparty-sh/slp-explorer\">こちら</a>に報告できます。<br>ページの再読み込みが役立つ場合があります。"])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冒険者"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのトークン"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPトークンエクスプローラー"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin Cashで構築されたトークンを閲覧する"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["「このウェブサイトは、<a target=\"_blank\" href=\"https://github.com/simpleledger/S LPDB\"> SLPDB </a>、の派生<a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\"> BitDB FH </a>、コードを表示して問題を送信<a target=\"_blank\" href=\"https://github.com/blockparty-sh/slp-explorer\"> Github </a>。<br>を使用して独自のトークンを作成する<a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\"> Electron Cash SLP Edition </a>。"])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新のトランザクション"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引チャート"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークン抹消"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換："])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信されたSLPトランザクション"])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受信したSLPトランザクション"])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークン残高"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引チャート"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["悪い住所"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定されたアドレスをデコードできません。 スペルミスがないか再確認してください。"])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPトランザクションの処理"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDBは現在このトランザクションを処理しています。 高負荷時には、トランザクションの処理に時間がかかる場合があります。 しばらくしてからもう一度お試しください。"])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引が見つかりません"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このトランザクションはSLPDBまたはBitDBで見つかりませんでした。 非常に古いか、スペルが間違っている可能性があります。"])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404お探しのページが見つかりませんでした"])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お探しのページが見つかりません。"])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["別の検索を試すか、ブラウザに戻ってください。これはバグだった可能性があります。 問題があった場合は、<a href=\"https://github.com/blockparty-sh/slp-explorer\">報告</a>してください。"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックで確認されたすべてのトランザクションを表示する"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックで確認されるのを待っているすべてのトランザクションを表示する"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どのトークンの保有者がBCHを受け取るべきか"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークンホルダー間で送信するBCHの量"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1行に1つのSimpleledgerアドレスを入力します"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electron Cashで「Pay to Many」を使用する"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPエクスプローラー"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレスを無視"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配当ヘルパー"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビットコイン現金取引"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計算する"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気のトークン"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのトークン"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トランザクション"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トランザクションハッシュ"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引チャート"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トランザクション識別子"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トランザクション"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークン識別子"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークン残高"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークン"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークン"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シンボル"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バージョン"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロック"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロック"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックの高さ"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックタイム"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブロックが作成されました"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["読み込んでいます..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["週間"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH Explorerで表示"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリップボードにコピー"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ1"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更不可のトークン1グループ"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更不可のトークン1子[親を表示]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子の非交換可能なトークン"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミントの歴史"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燃焼履歴"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[無し]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["豊富なリスト"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["統計"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["細部"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残高"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミントバトン"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミントバトンステータス"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バトンの未使用トランザクション出力の作成"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送る"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミント"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創世記"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TokenType1ジェネシストランザクションにはSLP入力がありません。<br> NFT1子トランザクションは、[Token Details]ペインの[Version]行を見ると入力を確認できます。"])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創世記以降の有効な取引"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なトークンの未使用のトランザクション出力"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有効なトークンアドレス"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロックされたビットコイン"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークンが作成された"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["燃やされたトークン"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["循環供給"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDBアドレスエラー"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力なし。"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メモリプール"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資料"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドキュメントの場所"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドキュメントチェックサム"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリエーター"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バージョン"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認済み"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アウトプット"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出力ベクトル"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイムスタンプ"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小数"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPアドレス"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現金住所"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧住所"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現金口座"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現金口座"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帰る"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家に帰る"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ページを再読み込み"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トランザクション識別子、アドレス、またはトークン名で検索..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非SLPトランザクション"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP無効の理由"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["これはSLPトランザクションではありません。"])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成されたトークン"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送信しました"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け取りました"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トークン識別子が必要"])}
}