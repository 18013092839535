import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8e9272e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tx-page container"
}
const _hoisted_2 = { class: "tables-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_container = _resolveComponent("info-container")!
  const _component_tx_details = _resolveComponent("tx-details")!
  const _component_tx_token = _resolveComponent("tx-token")!
  const _component_tx_operation = _resolveComponent("tx-operation")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createBlock(_component_Loading, {
    loading: _ctx.loading,
    error: _ctx.error
  }, {
    default: _withCtx(() => [
      (_ctx.result)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_info_container, {
              title: _ctx.title,
              token: {
          tokenId: _ctx.result.token.tokenId,
          documentUri: _ctx.result.token.documentUri,
          type: _ctx.result.token.type,
        }
            }, null, 8, ["title", "token"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_tx_details, {
                details: _ctx.result.details
              }, null, 8, ["details"]),
              _createVNode(_component_tx_token, {
                token: _ctx.result.token
              }, null, 8, ["token"])
            ]),
            _createVNode(_component_tx_operation, {
              inputs: _ctx.result.inputs,
              outputs: _ctx.result.outputs
            }, null, 8, ["inputs", "outputs"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading", "error"]))
}