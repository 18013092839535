import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19be015e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", {
        textContent: _toDisplayString(_ctx.$t(_ctx.title))
      }, null, 8, _hoisted_3),
      _createElementVNode("span", {
        textContent: _toDisplayString(_ctx.value ? _ctx.numberWithCommas(_ctx.value) : '--- soon ---')
      }, null, 8, _hoisted_4)
    ])
  ]))
}