import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "token-transactions" }
const _hoisted_2 = { class: "table-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pagination_table = _resolveComponent("pagination-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t("child_nfts")) + " (" + _toDisplayString(_ctx.numberWithCommas(_ctx.nft.length)) + ") ", 1),
    _createVNode(_component_pagination_table, {
      headers: _ctx.status.headers,
      items: _ctx.status.items,
      pages: _ctx.nft.allPage,
      change: _ctx.changePage
    }, null, 8, ["headers", "items", "pages", "change"])
  ]))
}