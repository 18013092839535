import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyText && _ctx.copyText(...args))),
          class: "fi fi-rr-duplicate copy-icon p-1 px-2"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["copy-popup", { show: _ctx.showPopup }])
    }, "copied!", 2)
  ]))
}