
import { defineComponent, PropType } from "vue";

// types
import { table_row } from "@/types/table.type";

// Components
import TableRow from "@/components/global/table/TableRow.vue";

//
export default defineComponent({
  components: { TableRow },
  name: "Table",
  props: {
    headers: Object as PropType<string[]>,
    items: {
      type: Object as PropType<table_row[]>,
      required: true,
    },
    "empty-message": {
      type: String,
      default: "No Items",
    },
  },
});
