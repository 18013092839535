
import { defineComponent } from "vue";

// Components
import rowContainer from "@/components/home/rowContainer.vue";

//
export default defineComponent({
  name: "Partners",
  components: { rowContainer },
  setup() {
    const partners = [
      {
        image: "/image/partners/fullstack.png",
        url: "https://fullstack.cash",
      },
      {
        image: "/image/partners/arquebus.png",
        url: "https://arquebus.io/",
      },
    ];

    return { partners };
  },
});
