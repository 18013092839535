
import { defineComponent, ref } from "vue";

// Use
import useSearch from "@/use/useSearch";

// Components
import InputSearch from "@/components/global/search/InputSearch.vue";
import { useRoute, useRouter } from "vue-router";

//
export default defineComponent({
  name: "GroupSearch",
  components: { InputSearch },
  setup() {
    //
    const route = useRoute();
    const router = useRouter();

    //
    const openFromOtherHomePage = ref(true);

    //
    router.beforeEach((to, from) => {
      if (to.fullPath === "/" && from.fullPath === "/") {
        // On first open home page
        openFromOtherHomePage.value = false;
      } else {
        openFromOtherHomePage.value = true;
      }
    });

    //
    let { search } = useSearch();

    //
    const searchWord = ref("");

    //
    function searchMethod() {
      search(searchWord.value);
      searchWord.value = "";
    }

    //
    return { searchWord, openFromOtherHomePage, searchMethod, route };
  },
});
