import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-362130d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabel-container" }
const _hoisted_2 = { class: "table-warp" }
const _hoisted_3 = { class: "table table-striped" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_row = _resolveComponent("table-row")!
  const _component_Loading = _resolveComponent("Loading")!
  const _component_pagination_nav = _resolveComponent("pagination-nav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Loading, {
      loading: _ctx.status.loading
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("table", _hoisted_3, [
            (_ctx.headers)
              ? (_openBlock(), _createElementBlock("thead", _hoisted_4, [
                  _createElementVNode("tr", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, key) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: key,
                        textContent: _toDisplayString(header ? _ctx.$t(header) : '')
                      }, null, 8, _hoisted_5))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.status.items, (cells, key) => {
                return (_openBlock(), _createBlock(_component_table_row, {
                  key: key,
                  cells: cells
                }, null, 8, ["cells"]))
              }), 128))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["loading"]),
    _createVNode(_component_pagination_nav, {
      itemsLength: _ctx.pages,
      onChange: _ctx.onPaginationChange
    }, null, 8, ["itemsLength", "onChange"])
  ]))
}