import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b984e95"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_row_container = _resolveComponent("row-container")!

  return (_openBlock(), _createBlock(_component_row_container, {
    "image-size": 150,
    title: _ctx.$t('index_page_partners')
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partners, (partner) => {
        return (_openBlock(), _createElementBlock("a", {
          class: "partners",
          key: partner.url,
          href: partner.url
        }, [
          _createElementVNode("img", {
            src: partner.image,
            alt: "",
            class: "partners-image"
          }, null, 8, _hoisted_2)
        ], 8, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }, 8, ["title"]))
}