
import { defineComponent } from "vue";
import GroupSearch from "@/components/global/search/GroupSearch.vue";
import AnalyticsContainer from "@/components/global/analytics/AnalyticsContainer.vue";

export default defineComponent({
  name: "Home-Hero",
  components: {
    GroupSearch,
    AnalyticsContainer,
  },
  setup() {
    const analyticsCardsItem = [
      { title: "tokens_created", value: null },
      { title: "index_page_token_burns", value: null },
      { title: "transactions", value: null },
    ];

    return { analyticsCardsItem };
  },
});
