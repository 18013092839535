export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger.info sedang menjalani penyelenggaraan, menjangkakan prestasi menurun."])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuatkan halaman..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Semua Token"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembantu dividen"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod sumber"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumber"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sambung"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op ... ralat berlaku!"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maaf atas kesulitan ini, kami akan meneliti perkara ini secepat mungkin. Sekiranya anda mahu, anda boleh melaporkan ralat ini <a href=\"https://github.com/blockparty-sh/slp-explorer\"> di sini </a>. <br> Memuat semula halaman mungkin akan membantu."])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penjelajah"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua Token"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penjelajah Token SLP"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semak imbas token yang dibina di Bitcoin Cash"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laman web ini dibina di atas <a target=\"_blank\" href=\"https://github.com/simpleledger/S LPDB\">SLPDB</a>, cabang dari<a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\">BitDB FH</a>, melihat kod dan mengemukakan masalah pada<a target=\"_blank\" href=\"https://github.com/blockparty-sh/slp-explorer\"> Github </a>.<br>Buat token anda sendiri menggunakan<a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\"> Edisi SLP Tunai Elektron </a>."])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi Terkini"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta Transaksi"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembakaran Token"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pertukaran:"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi SLP yang dihantar"])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menerima Transaksi SLP"])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baki Token"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta Transaksi"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Tidak Baik"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maaf, kami tidak dapat menyahkod alamat yang diberikan. Periksa semula ia tidak salah eja."])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memproses Transaksi SLP"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB sedang memproses transaksi ini. Semasa beban tinggi mungkin memerlukan lebih lama untuk memproses transaksi. Sila cuba sebentar lagi."])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi Tidak Dijumpai"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi ini tidak dijumpai di SLPDB atau BitDB. Ia mungkin sangat tua atau salah eja."])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 Tidak Dijumpai :("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maaf, tidak dapat mencari halaman yang anda cari."])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba cari carian lain atau kembali ke penyemak imbas anda, ini mungkin bug. Sekiranya ada, sila <a href=\"https://github.com/blockparty-sh/slp-explorer\">laporkan</a>!"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat semua transaksi yang disahkan dalam satu blok"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat semua transaksi yang menunggu untuk disahkan dalam satu blok"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pemegang token mana yang harus diterima bch"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["berapa banyak bch untuk dihantar antara pemegang token"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["masukkan satu simpleledger: alamat setiap baris"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gunakan \"Bayar kepada Ramai\" dengan Tunai Elektron"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penjelajah SLP"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abaikan Alamat"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembantu Dividen"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi Tunai Bitcoin"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kira"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi Tunai Bitcoin"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua Token"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urus Niaga"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi Hash"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta Transaksi"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengecam Transaksi"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengecam Token"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imbangan Token"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simbol"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versi"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketinggian Blok"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masa Blok"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok Dibuat"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memuat..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahun"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebulan"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minggu"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hari"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat di Bitcoin Cash Explorer"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salin ke papan keratan"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taip satu"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token bukan kumpulan satu"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token satu anak yang tidak boleh berjangkit [lihat ibu bapa]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token kanak-kanak yang tidak berjangkit"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sejarah Pudina"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sejarah Bakar"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yang belum selesai"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[Tiada]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senarai Kaya"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistik"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perincian"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seimbang"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint Baton"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Baton Minting"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minting Output Transaksi Tanpa Baton"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hantar"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pudina"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kejadian"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TokenType1 genesis transactions do not have any SLP inputs.<br>NFT1 Child transactions can see the input from looking at the Version row in the Token Details pane."])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi Sah sejak Kejadian"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasil Transaksi Tidak Token Token yang Sah"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Token Yang Sah"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satoshi terkunci"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Minted"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Dibakar"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekalan Beredar"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ralat Alamat SLPDB"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiada input."])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jenis"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolam memori"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumen"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokasi Dokumen"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumen Checksum"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pencipta"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versi"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disahkan"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluaran"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vektor Keluaran"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cap waktu"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perpuluhan"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat SLP"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Tunai"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat Warisan"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akaun Tunai"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akaun Tunai"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pergi balik"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balik rumah"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muat semula Halaman"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cari mengikut pengecam transaksi, alamat atau nama token..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaksi Bukan SLP"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebab Tidak Sah SLP"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ini bukan transaksi SLP."])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Dibuat"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dihantar"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menerima"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id Token Diperlukan"])}
}