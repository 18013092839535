
import { defineComponent } from "vue";

//
export default defineComponent({
  name: "RowContainer",
  props: {
    "image-size": {
      type: Number,
      default: 80,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    function toString(Int: number) {
      return String(Int).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return { toString };
  },
});
