
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const sourceCodeList = [
      {
        title: "SLP Explorer",
        url: "https://github.com/salemkode/slp-explorer",
      },
      {
        title: "SLP Explorer backend",
        url: "https://github.com/salemkode/slp-explorer-backend",
      },
      {
        title: "SLP indexer",
        url: "https://github.com/Permissionless-Software-Foundation/psf-slp-indexer",
      },
    ];

    const resources = [
      {
        title: "simpleledger.cash",
        url: "https://simpleledger.cash/",
      },
      {
        title: "specification",
        url: "https://github.com/simpleledger/slp-specifications",
      },
      {
        title: "slp-sdk",
        url: "https://developer.bitcoin.com/slp/",
      },
    ];

    const connectList = [
      {
        title: "Explorer developer",
        url: "https://t.me/salemkode",
      },
      {
        title: "SimpleLedger Chat",
        url: "https://t.me/simpleledger",
      },
      {
        title: "SimpleLedger Youtube",
        url: "https://www.youtube.com/channel/UC_WJO3lyHAhdU6oEk0lhMLw",
      },
      {
        title: "SimpleLedger Twitter",
        url: "https://twitter.com/SimpleLedger",
      },
      {
        title: "Fountainhead Chat",
        url: "http://t.me/fountainheadcash",
      },
    ];

    return {
      sourceCodeList,
      resources,
      connectList,
    };
  },
});
