export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger.info currently undergoing maintenance; expect degraded performance."])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading view..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All Tokens"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividend Helper"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Code"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops... an error occurred!"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry for the inconvenience; we will look into this as soon as possible. If you'd like, you can report this error <a href=\"https://github.com/blockparty-sh/slp-explorer\">here</a>.<br>Reloading the page might help."])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Tokens"])},
  "mainmenu_donate_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate to us"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Tokens Explorer"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore Bitcoin Cash tokens with analytics and transactions."])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is built on top of <a href=\"https://github.com/simpleledger/SLPDB\">SLPDB</a>, an offshoot of <a href=\"https://github.com/fountainhead-cash/bitd\">BitDB FH</a>. View code and submit issues on <a href=\"https://github.com/blockparty-sh/slp-explorer\">Github</a>.<br>Make your own tokens using <a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases/\">Electron Cash SLP Edition</a>."])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Transactions"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions Charts"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Burns"])},
  "index_page_partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners:"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent SLP Transactions"])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received SLP Transactions"])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Balances"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions Chart"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Address"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we cannot decode the address given. Double check that it isn't misspelled."])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing SLP Transaction"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB is currently processing this transaction. During high load it may take longer to process transactions. Please try again in a few moments."])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Not Found"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This transaction was not found in SLPDB or BitDB. It may have been very old or misspelled."])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 Not Found :("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, can't find the page you were looking for."])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please try another search or go back in your browser; this may have been a bug. If it was, please <a href=\"https://github.com/blockparty-sh/slp-explorer\">report it</a>!"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all transactions confirmed in a block"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all transactions waiting to be confirmed in a block"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which token's holders should receive BCH"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much BCH to send between the token holders"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter one simpleledger: address per line"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use \"Pay to Many\" with Electron Cash"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Explorer"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignore Addresses"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividend Helper"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin Cash Transaction"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Tokens"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Tokens"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction Hash"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions Chart"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction ID"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token ID"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Balance"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
  "token-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token details"])},
  "tx-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tx details"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocks"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Height"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Time"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Created"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View on BCH Explorer"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to clipboard"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type1"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-Group"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-Child [view parent]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child NFTs"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint History"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burn History"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[none]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rich List"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint Baton"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minting Baton Status"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minting Baton UTXO"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["send"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mint"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type1 token genesis transactions do not have any SLP inputs.<br>NFT1 Child transactions can see the input from looking at the Version row in the Token Details pane."])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Transactions Since Genesis"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Token UTXOs"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Token Addresses"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satoshis Locked Up"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens Minted"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens Burned"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circulating Supply"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB Address Error"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No inputs."])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mempool"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document URI"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Checksum"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inputs"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outputs"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V<sub>out</sub>"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Address"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Address"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legacy Address"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Account"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Accounts"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Home"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload Page"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by Address / TX (Version) ID"])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NON-SLP Transaction"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Invalid Reason"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a SLP transaction."])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens Created"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token ID Required"])},
  "nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT"])},
  "tokenstats_nft_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "tokenstats_nft_burnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burnt Transaction ID"])}
}