
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    error: {
      type: Object as PropType<{ statusCode: number; message: string }>,
      required: true,
    },
  },
  setup() {
    const errorImages = {
      404: "/image/errors/not_found.svg",
      400: "/image/errors/empty.svg",
      503: "/image/errors/server-down.svg",
      429: "/image/errors/server-down.svg",
    };

    return { errorImages };
  },
});
