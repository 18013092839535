
import { defineComponent, PropType } from "vue";
import Error from "@/components/global/Error.vue";

export default defineComponent({
  components: { Error },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Object as PropType<{ statusCode: number; message: string }>,
    },
  },
});
