import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7423582d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading-container"
}
const _hoisted_2 = { class: "loading" }
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h3", {
                class: "loading-text",
                textContent: _toDisplayString(_ctx.$t('loading_dank_memes'))
              }, null, 8, _hoisted_3)
            ])
          ]))
        : (_ctx.error)
          ? (_openBlock(), _createBlock(_component_Error, {
              key: 1,
              error: _ctx.error
            }, null, 8, ["error"]))
          : _renderSlot(_ctx.$slots, "default", { key: 2 }, undefined, true)
    ]),
    _: 3
  }))
}