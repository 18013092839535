export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger.info Atualmente em Manutenção, por favor aguarde alguns momentos."])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caregando página..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos os tokens"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividendo Ajudante"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código fonte"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ops! Ocorreu um erro!"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe pelo inconveniente, vamos analisá-lo o mais rápido possível. Se você quiser, pode relatar este erro <a href=\"https://github.com/blockparty-sh/slp-explorer\">Aqui</a>.<br> Ou recarregar a página pode ajudar."])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os Tokens"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorador de tokens SLP"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busque tokens criados com Bitcoin Cash"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este site é construído sobre<a target=\"_blank\" href=\"https://github.com/simpleledger/SLPDB \"> SLPDB </a>,una rama de<a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\"> BitDB FH </a>, visualizar código e enviar problemas em<a target=\"_blank\" href=\"https://github.com/blockparty-sh/slp-explorer\"> Github </a>.<br>Crie seus próprios tokens usando<a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\"> Electron Cash SLP Edition </a>"])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas transações"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráficos de transações"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens quemados"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchanges:"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transações SLP enviadas "])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transações SLP recebidas "])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo de tokens"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de transações"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço incorreto"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe, não podemos decodificar o endereço fornecido. Verifique se não está incorreto."])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamento de transações SLP"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O SLPDB está atualmente processando esta transação. Durante uma sobrecarga, pode levar mais tempo para processar transações. Tente novamente em alguns instantes."])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação não encontrada"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta transação não foi encontrada no SLPDB ou no BitDB. Pode ter sido muito antigo ou mal escrito."])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 não encontrado :("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desculpe, não consigo encontrar a página que você estava procurando."])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tente outra pesquisa ou retorne ao seu navegador, isso pode ter sido um erro. Se sim, por favor<a href=\"https://github.com/blockparty-sh/slp-explorer\">report</a> it!</p>"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja todas as transações confirmadas em um bloco"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja todas as transações aguardando confirmação em um bloco"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quais titulares de token devem receber BCH"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quanto bch enviar entre titulares de token"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["insira um simpleledger: um endereço por linha"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use \"Pague para muitos \" com o Electron Cash"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLP Explorer "])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ignorar Endereços"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividendo Ajudante"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação efetiva de Bitcoin Cash"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens populares"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas os Tokens"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transações"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash da transação"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de Transações"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Txid"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tx"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Id"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo de Token"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simbolo"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versão"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereços"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocl"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocos"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura do bloco"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo de bloco"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bloco criado"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dia"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver no BCH Explorer"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar para area de transferencia"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo 1"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo NFT1"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-filha [ver mãe]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT Infantis"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia de mint"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de queima"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendente"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[nenhuma]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista do mais ricos"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatísticas"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bastão de cunhagem"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status do bastão de cunhagem"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bastão de cunhagem UTXO"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enviar"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cunhar"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As transações do Genesis Token Tipo 1 não possuem nenhuma entrada SLP. As transações filho do NFT1 podem exibir a entrada observando a linha Versão no painel Detalhes da guia."])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Txns válidas desde o Genesis"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token UTXO válidos"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereços de token válidos"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satoshis Bloqueados"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens cunhadas"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens queimadas"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidadeem circulação"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de de endereço SLPDB"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Srn entradas."])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mempool"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento URI"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checagem de documentos"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criador"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versão"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificado"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entradas"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saidas"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vout"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca de tempo"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimais"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço SLP"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de efectivo"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço legacy"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta Cash"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas cash"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ir par o inicio"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarregar página"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por txid, endereço ou nome do token..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação não SLP"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo inválido de SLP"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta não é uma transação SLP."])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokens criados"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebido"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificação de Token nescessária."])}
}