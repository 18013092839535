import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_cell = _resolveComponent("table-cell")!

  return (_openBlock(), _createElementBlock("tr", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cells, (cell, key) => {
      return (_openBlock(), _createBlock(_component_table_cell, {
        key: key,
        item: cell
      }, null, 8, ["item"]))
    }), 128))
  ]))
}