export default {
  "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["simpleledger.info বর্তমানে রক্ষণাবেক্ষণের কাজ চলছে, অবনতিশীল পারফরম্যান্স প্রত্যাশিত"])},
  "loading_dank_memes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পৃষ্ঠা লোড হচ্ছে..."])},
  "homepage_btn_view_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সমস্ত টোকেন দেখুন"])},
  "homepage_btn_dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লভ্যাংশ সহকারী"])},
  "footer_source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোডের উৎস"])},
  "footer_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সংস্থান"])},
  "footer_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["জুড়ুন"])},
  "error_modal_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ওহ! একটি ত্রুটি ঘটেছে!"])},
  "error_modal_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অসুবিধার জন্য দুঃখিত, আমরা যত তাড়াতাড়ি সম্ভব এটিকে দেখব। আপনি যদি চান, আপনি এই ত্রুটি রিপোর্ট করতে পারেন <a href=\"https://github.com/blockparty-sh/slp-explorer\"> এখানে </a>। <br> পৃষ্ঠাটি পুনরায় লোড করুন এটি আপনাকে সহায়তা করতে পারে। ।"])},
  "mainmenu_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["অনুসন্ধানকারী"])},
  "mainmenu_all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সমস্ত টোকেনগুলি"])},
  "index_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সাধারণ লেজার প্রোটোকল(এসএলপি) টোকেন এক্সপ্লোরার"])},
  "index_page_subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিটকয়েন ক্যাশে তৈরিকৃত টোকেনগুলি দেখুন"])},
  "index_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এই ওয়েবসাইটটি  নির্মিত হয়েছে<a target=\"_blank\" href=\"https://github.com/simpleledger/SLPDB\"> SLPDB </a>একটি শাখার শীর্ষে<a target=\"_blank\" href=\"https://github.com/fountainhead-cash/bitd\"> BitDB FH </a>,কোড দেখুন এবং সমস্যাগুলি জমা দিন<a target=\"_blank\" href=\"https://github.com/blockparty-sh/slp-explorer\"> Github </a>।<br>আপনার নিজের টোকেন তৈরি করুন<a href=\"https://github.com/simpleledger/Electron-Cash-SLP/releases\">ইলেক্ট্রন ক্যাশ এসএলপি সংস্করণ ব্যবহার করে</a>।"])},
  "index_page_latest_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সর্বশেষ লেনদেনগুলো"])},
  "index_page_transaction_charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেনদেনের তালিকা"])},
  "index_page_token_burns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেন পুড়ে গেছে"])},
  "index_page_exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এক্সচেঞ্জ:"])},
  "address_page_sent_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রেরিত এসএলপি লেনদেনগুলো"])},
  "address_page_received_slp_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রাপ্ত এসএলপি লেনদেনগুলো"])},
  "address_page_token_balances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেন ব্যালেন্স"])},
  "address_page_transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেনদেনগুলির তালিকা"])},
  "bad_address_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ভূল ঠিকানা"])},
  "bad_address_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দুঃখিত, আমরা প্রদত্ত ঠিকানাটি বুঝতে পারছি না। এটি ভুল বানান কিনা পূনরায় যাচাই করুন।"])},
  "processing_tx_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এসএলপি লেনদেন প্রক্রিয়া করছে"])},
  "processing_tx_page_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB বর্তমানে এই লেনদেন প্রক্রিয়া করছে। উচ্চ লোডের সময় লেনদেনগুলি প্রক্রিয়া করতে একটু বেশি সময় লাগতে পারে। কিছুক্ষণ পর আবার চেষ্টা করুন।"])},
  "no_tx_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেনদেন পাওয়া যায়নি"])},
  "no_tx_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এই লেনদেনটি SLPDB বা BitDB পাওয়া যায় নি। এটি খুব পুরানো বা ভুল বানান হতে পারে।"])},
  "404_page_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 পাওয়া যায়নি :("])},
  "404_page_subtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দুঃখিত, আপনি যে পৃষ্ঠার সন্ধান করেছেন তা খুঁজে পাওয়া যাচ্ছে না।"])},
  "404_page_subtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দয়া করে অন্য অনুসন্ধান করে দেখুন বা আপনার ব্রাউজারে ফিরে যান, এটি একটি ত্রুটি হতে পারে। যদি ত্রুটি হয়, দয়া করে <a href=\"https://github.com/blockparty-sh/slp-explorer\"> রিপোর্ট করুন </a>!"])},
  "block_page_view_all_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["একটি ব্লকে সমস্ত নিশ্চিত লেনদেনগুলো দেখুন"])},
  "block_page_view_all_mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোনও ব্লকে নিশ্চিত হওয়ার জন্য অপেক্ষা করা সমস্ত লেনদেন দেখুন"])},
  "dividend_helper_tokenid_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোন টোকেন হোল্ডারদের BCH পাওয়া উচিৎ"])},
  "dividend_helper_bch_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেন হোল্ডারদের মধ্যে কত BCH পাঠাতে হবে"])},
  "dividend_helper_ignore_addresses_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" প্রতি লাইনে একটি এসএলপি: ঠিকানা প্রবেশ করান:"])},
  "dividend_helper_calculate_subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইলেক্ট্রন ক্যাশে \"Pay to Many\" ব্যবহার করুন"])},
  "slp_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এসএলপি এক্সপ্লোরার"])},
  "ignore_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ঠিকানাগুলি উপেক্ষা করুন"])},
  "dividend_helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লভ্যাংশ সহায়ক"])},
  "bitcoin_cash_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিটকয়েন ক্যাশের লেনদেন"])},
  "bch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BCH"])},
  "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["হিসাব"])},
  "popular_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["জনপ্রিয় টোকেনগুলো"])},
  "all_tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সমস্ত টোকেন"])},
  "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেনদেন"])},
  "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেনদেনগুলো"])},
  "transaction_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেনদেনের হ্যাশ"])},
  "transactions_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেনদেনের তালিকা"])},
  "txid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেনদেন শনাক্তকারী"])},
  "tx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেনদেন"])},
  "token_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেন আইডি/শনাক্তকারী"])},
  "token_balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেন ব্যালেন্স"])},
  "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেন"])},
  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেনগুলি"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নাম"])},
  "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["প্রতীক"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পরিমাণ"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সংস্করণ"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ঠিকানা"])},
  "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ঠিকানাগুলো"])},
  "block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্লক"])},
  "blocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্লকগুলো"])},
  "block_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্লক উচ্চতা"])},
  "block_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্লকের সময়"])},
  "block_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্লক তৈরি হয়েছে"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লোডিং হচ্ছে..."])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সাল"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মাস"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সপ্তাহ"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দিন"])},
  "view_on_bch_explorer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিটকয়েন ক্যাশ এক্সপ্লোরারে দেখুন"])},
  "copy_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ক্লিপবোর্ডে অনুলিপি করুন"])},
  "type1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(1)এক টাইপ করুন"])},
  "nft1_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-গ্রুপ"])},
  "nft1_child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT1-শিশু [পিতামাতা দেখুন]"])},
  "child_nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বাচ্চাদের NFTs"])},
  "mint_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আবিষ্কারের ইতিহাস"])},
  "burn_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পোড়ানোর ইতিহাস"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ঝুলন্ত"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["[কেউ না]"])},
  "rich_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সমৃদ্ধ তালিকা"])},
  "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পরিসংখ্যান"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বিবরণ"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ব্যালেন্স"])},
  "mint_baton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আবিষ্কার বাটন"])},
  "minting_baton_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আবিষ্কার বাটনের অবস্থা"])},
  "minting_baton_utxo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আবিষ্কার বাটনের অপ্রত্যাশিত লেনদেনেগুলোর আউটপুট"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাঠান"])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আবিষ্কার"])},
  "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["উৎস"])},
  "genesis_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেনটাইপ ১ উৎসের লেনদেনেগুলোর কোনও এসএলপি ইনপুট নেই। <br>NFT1 সন্তানের লেনদেন টোকেন বিশদ ফলকে সংস্করণ সারিটি দেখে ইনপুটটি দেখতে পারে।"])},
  "tokenstats_valid_token_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["উৎস থেকেই বৈধ লেনদেন"])},
  "tokenstats_valid_token_utxos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আকস্মীক লেন-দেনের ফলাফলগুলি বৈধ টোকেন"])},
  "tokenstats_valid_token_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["বৈধ টোকেনের ঠিকানা"])},
  "tokenstats_satoshis_locked_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["তালাবদ্ধ সাতোসি"])},
  "tokenstats_tokens_minted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আবিষ্কৃত টোকেন"])},
  "tokenstats_tokens_burned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেন পুড়ে গেছে"])},
  "tokenstats_circulating_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সরবরাহকৃত সঞ্চালন"])},
  "slpdb_address_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLPDB ঠিকানার ত্রুটি"])},
  "no_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["কোনও ইনপুট নেই।"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টাইপ"])},
  "mempool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["মেমপুল"])},
  "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দলিল"])},
  "document_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নথির অবস্থান"])},
  "document_checksum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ডকুমেন্ট চেকসাম"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["স্রষ্টা"])},
  "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["সংস্করণ"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["যাচাইকৃত"])},
  "inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ইনপুট"])},
  "outputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আউটপুট"])},
  "vout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["আউটপুট ভেক্টর"])},
  "timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টাইমস্ট্যাম্প"])},
  "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["দশমিক"])},
  "slp_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এসএলপি ঠিকানা"])},
  "cash_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ক্যাশ এড্রেস"])},
  "legacy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লিগ্যাসি এড্রেস"])},
  "cash_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নগদ হিসাব"])},
  "cash_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নগদ হিসাবগুলি"])},
  "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ফিরে যান"])},
  "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["হোমে ফিরে যান"])},
  "reload_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পৃষ্ঠা পূনরায় লোড করুন"])},
  "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["লেনদেন শনাক্তকারী, ঠিকানা বা টোকেন নাম দিয়ে অনুসন্ধান করুন..."])},
  "nonslp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["নন-এসএলপি লেনদেন"])},
  "slp_invalid_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এসএলপি ভুল কারণ"])},
  "this_is_not_a_slp_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["এটি একটি এসএলপি লেনদেন নয়।"])},
  "tokens_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেন তৈরি হয়েছে"])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["পাঠানো হয়েছে"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["গৃহীত হয়েছে"])},
  "tokenid_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["টোকেন আইডি প্রয়োজন"])}
}