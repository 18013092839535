
import { defineComponent } from "vue";

// Types
import { PropType } from "vue";
import { table_cell } from "@/types/table.type";

// Components
import AppLink from "@/components/global/AppLink.vue";
import InfoIcon from "@/components/global/infoContainer/InfoIcon.vue";
import Copy from "@/components/global/Copy.vue";

//
export default defineComponent({
  name: "TableCell",
  // eslint-disable-next-line vue/no-unused-components
  components: { AppLink, Copy, InfoIcon },
  props: {
    item: {
      type: [String, Number, Object] as PropType<table_cell>,
      required: true,
    },
  },
});
