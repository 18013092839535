
import { defineComponent } from "vue";

// Components
import AppLink from "@/components/global/AppLink.vue";
import NavSearch from "@/components/global/search/NavSearch.vue";

//
export default defineComponent({
  components: { AppLink, NavSearch },
  setup() {
    const routers = {};

    //
    return { routers };
  },
});
