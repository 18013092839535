
import { defineComponent } from "vue";

// Components
import NavBar from "@/components/global/NavBar.vue";
import Footer from "@/components/global/Footer.vue";

export default defineComponent({
  components: { NavBar, Footer },
});
