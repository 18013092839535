import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "token-page container"
}
const _hoisted_2 = { class: "tables-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_container = _resolveComponent("info-container")!
  const _component_analytics_token = _resolveComponent("analytics-token")!
  const _component_token_details = _resolveComponent("token-details")!
  const _component_token_status = _resolveComponent("token-status")!
  const _component_token_transactions = _resolveComponent("token-transactions")!
  const _component_token_nfts = _resolveComponent("token-nfts")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createBlock(_component_Loading, {
    loading: _ctx.loading,
    error: _ctx.error
  }, {
    default: _withCtx(() => [
      (_ctx.result)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_info_container, {
              title: `${_ctx.result.details.ticker} ${_ctx.$t(_ctx.SLP_Type_Name)}`,
              token: {
          documentUri: _ctx.result.details.documentUri,
          tokenId: _ctx.result.details.tokenId,
          type: _ctx.result.details.type,
          parentGroupId: _ctx.result.details.parentGroupId,
        }
            }, null, 8, ["title", "token"]),
            _createVNode(_component_analytics_token, {
              "token-stats": _ctx.result.stats
            }, null, 8, ["token-stats"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_token_details, {
                "token-details": _ctx.result.details
              }, null, 8, ["token-details"]),
              _createVNode(_component_token_status, {
                "token-stats": _ctx.result.stats
              }, null, 8, ["token-stats"])
            ]),
            _createVNode(_component_token_transactions, {
              tx: _ctx.result.tx,
              "get-tx": _ctx.getTx
            }, null, 8, ["tx", "get-tx"]),
            (_ctx.result.nft)
              ? (_openBlock(), _createBlock(_component_token_nfts, {
                  key: 0,
                  nft: _ctx.result.nft,
                  "get-nft": _ctx.getNft
                }, null, 8, ["nft", "get-nft"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading", "error"]))
}