import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NavBar),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      }, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            mode: "out-in",
            name: "fade"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }))
    ]),
    _createVNode(_component_Footer)
  ], 64))
}