import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container my-5" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", {
      class: "table-title",
      textContent: _toDisplayString(_ctx.title)
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      class: "row-container__row-items",
      style: _normalizeStyle({ '--image-size': _ctx.imageSize + 'px' })
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4)
  ]))
}